'use client'

import { debounce } from 'lodash'
import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import { useContext, useEffect, useState } from 'react'
import FileService from '@/contexts/FileServiceContext'
import PopUp from '@/contexts/PopUpContext'
import Toast from '@/contexts/ToastContext'
import Update from '@/contexts/UpdateContext'
import Wallet from '@/contexts/WalletContext'
import { fetchAccount } from '@/lib/account/fetchAccount'
import { fetchRelease } from '@/lib/release/fetchRelease'
import { Account, ButtonColor, ButtonFont, ButtonVariant } from '@/lib/types'
import {
  AccountDisplayType,
  displayForAccount,
} from '@/lib/utils/displayForAccount'
import { detectMedDown } from '@/lib/utils/useScreenWidth'
import Logo from '@/public/assets/logo'
import CreateEntryModal from './CreateEntryModal'
import LoginModal from './LoginModal'
import NotificationsModal from './NotificationsModal'
import Button from './tokens/Button'
import Icon from './tokens/Icon'

export default function Navigation() {
  const pathname = usePathname()
  const router = useRouter()
  const isSearchPage = pathname.includes('/search')
  const [account, setAccount] = useState<Account | null>(null)
  const { wallet, sessionSignature, reconnecting } = useContext(Wallet.Context)
  const { pendingUpload, clearPendingUpload } = useContext(FileService.Context)
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const navLinks = ['home', 'search', 'editorial']
  const { addToast } = useContext(Toast.Context)
  const { showPopUp } = useContext(PopUp.Context)
  const { date } = useContext(Update.Context)
  const isMedDown = detectMedDown()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const getAccount = async (publicKey: string) => {
    if (publicKey) {
      const fetchedAccount = await fetchAccount(publicKey)
      setAccount(fetchedAccount)
    } else {
      setAccount(null)
    }
  }

  useEffect(() => {
    const sessionSignatureLocalStorage =
      localStorage.getItem('sessionSignature')

    if (sessionSignatureLocalStorage) {
      setIsLoggedIn(true)
      const publicKey = JSON.parse(sessionSignatureLocalStorage).publicKey
      getAccount(publicKey)
    }
  }, [])

  useEffect(() => {
    if (wallet.publicKey) {
      getAccount(wallet.publicKey.toBase58())
    } else {
      setAccount(null)
      setIsLoggedIn(false)
    }
  }, [wallet.publicKey, date])

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const handleOpenCreateModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setMenuOpen(false)
    setTimeout(() => {
      setOpenCreateModal(true)
    }, 0)
  }

  const handleOpenLoginModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setTimeout(() => {
      setOpenLoginModal(true)
    }, 0)
  }

  const handleCloseSearch = () => {
    const windowHistoryLength = window?.history?.length

    if (windowHistoryLength > 2) {
      router.back()
    } else {
      router.push('/')
    }
  }

  useEffect(() => {
    if (
      pendingUpload &&
      pendingUpload.status === 'done' &&
      pendingUpload.type === 'release'
    ) {
      const handlePendingReleaseToast = async () => {
        if (pendingUpload.publicKey && !pendingUpload.imagePreview) {
          const { release } = await fetchRelease(pendingUpload.publicKey)
          addToast({
            message: 'Your release is now processing',
            variant: 'uploadComplete',
            image: release?.metadata.image,
            link: '/releases/uploading',
          })
        } else if (pendingUpload.imagePreview) {
          addToast({
            message: 'Your release is now processing',
            variant: 'uploadComplete',
            image: pendingUpload.imagePreview,
            link: '/releases/uploading',
          })
        }
      }

      handlePendingReleaseToast()
    }
  }, [pendingUpload])

  useEffect(() => {
    if (
      pendingUpload &&
      pathname === `/releases/${pendingUpload.slug || pendingUpload.publicKey}`
    ) {
      clearPendingUpload()
    }
  }, [pendingUpload, pathname])

  return (
    <div>
      {/* //desktop */}
      <div className="fixed ml-28 mt-28 hidden w-[260px] lg:block">
        <div className="w-[77px]">
          <Link href="/">
            <Logo className="blue-82 hover:opacity-80" />
          </Link>
        </div>
        <div className="flex flex-col pt-72">
          {navLinks.map((link, index) => {
            if (link === 'feed' && !account) return null

            return (
              <Link
                key={index}
                className="body-1 w-min pb-8 capitalize text-black md:hover:opacity-70"
                href={`/${link === 'home' ? '/' : link}`}
              >
                {link}
              </Link>
            )
          })}
          <Link
            className="body-1 w-min pb-8 capitalize !text-black md:hover:opacity-70"
            href={`/feed`}
          >
            Feed
          </Link>

          <div className="w-min pb-8">
            <CreateEntryModal />
          </div>

          <Link
            className="body-1 w-min pb-8 capitalize !text-black md:hover:opacity-70"
            href={`/about`}
          >
            About
          </Link>

          {!isLoggedIn && !account && reconnecting && (
            <p className="body-1 w-min max-w-[250px] truncate pb-8 text-grey-45 hover:opacity-80">
              Connecting <span className="pending r-[12px] absolute"></span>
            </p>
          )}
          {!isLoggedIn && !account && !reconnecting && (
            <div className="w-fit">
              <LoginModal
                labelText="Sign in"
                variant="navigation"
                labelTextColor={ButtonColor.Grey}
              />
            </div>
          )}
          {account && (
            <Link
              className="body-1 w-min max-w-[250px] truncate pb-8 text-grey-45 hover:opacity-80"
              href={`/profiles/${account.handle}`}
            >
              {displayForAccount(account, AccountDisplayType.Name)}
            </Link>
          )}
          {pendingUpload &&
            pendingUpload.type === 'release' &&
            pendingUpload.status !== 'done' &&
            pendingUpload.status !== 'scheduled' &&
            pendingUpload.status !== 'error' && (
              <Link
                href="/releases/uploading"
                className="body-1 w-min max-w-full truncate pb-8 text-grey-45 hover:opacity-80"
              >
                {pendingUpload.status === 'processing'
                  ? 'Processing'
                  : 'Uploading'}
                <span className="pending" />
              </Link>
            )}
        </div>
      </div>

      {/* mobile */}
      <div className="fixed left-0 z-10 col-start-1 col-end-[-1] w-full bg-white  lg:hidden">
        <div className="relative flex w-full justify-between bg-white p-12 md:p-20">
          <div className="flex flex-row">
            <div className="w-fit">
              <Link
                href="/"
                onClick={() =>
                  debounce(() => {
                    setMenuOpen(false)
                  }, 100)()
                }
              >
                <Logo className="black hover:opacity-70" />
              </Link>
            </div>
            {wallet.publicKey &&
              sessionSignature &&
              !showPopUp &&
              pathname !== '/notifications' &&
              isMedDown && (
                <div className="ml-20">
                  <NotificationsModal
                    view={'mobile'}
                    modalLabelFont={ButtonFont.Body1}
                  />
                </div>
              )}
          </div>
          <div className="flex space-x-16">
            {!menuOpen && !isSearchPage && (
              <Link href="/search" className="cursor-pointer">
                <Icon
                  className="black"
                  name="searchLarge"
                  width={20}
                  height={20}
                />
              </Link>
            )}
            {!isSearchPage && (
              <Button
                variant={ButtonVariant.Icon}
                color={ButtonColor.Black}
                icon={menuOpen ? 'closeLarge' : 'hamburger'}
                className="black md:hover:opacity-70"
                onClick={toggleMenu}
                styleOverride="border-none"
              />
            )}

            {isSearchPage && (
              <Button
                variant={ButtonVariant.Icon}
                color={ButtonColor.Black}
                icon="closeLarge"
                className="black md:hover:opacity-70"
                onClick={() => handleCloseSearch()}
              />
            )}
          </div>
        </div>

        {menuOpen && (
          <>
            <div className="z-10 flex flex-col  bg-white px-12 pb-[12px] pt-[40px] md:px-20 md:pb-20">
              {navLinks.map((link, index) => {
                if (link === 'search') return null

                return (
                  <Link
                    key={index}
                    className="body-1 w-min pb-8 capitalize text-black sm:hover:opacity-70"
                    href={`/${link === 'home' ? '/' : link}`}
                    onClick={() =>
                      debounce(() => {
                        setMenuOpen(false)
                      }, 100)()
                    }
                  >
                    {link}
                  </Link>
                )
              })}
              <Link
                className="body-1 w-min pb-8 capitalize text-black md:hover:opacity-70"
                href={`/feed`}
                onClick={() =>
                  debounce(() => {
                    setMenuOpen(false)
                  }, 100)()
                }
              >
                Feed
              </Link>

              <div className="pb-8">
                <Button
                  onClick={(e) => handleOpenCreateModal(e)}
                  color={ButtonColor.Unstyled}
                  textColor={ButtonColor.Black}
                  variant={ButtonVariant.Unstyled}
                  label={'Create'}
                  font={ButtonFont.Body1}
                  styleOverride="md:min-w-min"
                />
              </div>
              {wallet.publicKey && (
                <Link
                  href={'/notifications'}
                  className="body-1 w-min pb-8 capitalize text-black sm:hover:opacity-70"
                  onClick={() =>
                    debounce(() => {
                      setMenuOpen(false)
                    }, 100)()
                  }
                >
                  {'Notifications'}
                </Link>
              )}

              <Link
                className="body-1 w-min pb-8 capitalize text-black md:hover:opacity-70"
                href={`/about`}
                onClick={() =>
                  debounce(() => {
                    setMenuOpen(false)
                  }, 100)()
                }
              >
                About
              </Link>

              {account && sessionSignature ? (
                <Link
                  className="body-1 w-min max-w-full truncate pb-8 text-grey-45 hover:opacity-80"
                  href={`/profiles/${account.handle}`}
                  onClick={() =>
                    debounce(() => {
                      setMenuOpen(false)
                    }, 100)()
                  }
                >
                  {displayForAccount(account, AccountDisplayType.Name)}
                </Link>
              ) : (
                <div className="pb-8">
                  <Button
                    onClick={(e) => handleOpenLoginModal(e)}
                    color={ButtonColor.Grey}
                    textColor={ButtonColor.Grey}
                    variant={ButtonVariant.Unstyled}
                    label={'Sign in'}
                    font={ButtonFont.Body1}
                    styleOverride="md:min-w-min"
                  />
                </div>
              )}
            </div>
          </>
        )}

        {menuOpen && (
          <div
            className="backgroundOverlay absolute left-0 top-0 z-[-1] mt-12 h-[100vh] w-full overflow-hidden"
            onClick={() => setMenuOpen(false)}
          ></div>
        )}
      </div>

      <CreateEntryModal
        forceOpen={openCreateModal}
        setForceOpen={setOpenCreateModal}
        hideTrigger={true}
      />

      <LoginModal
        forceOpen={openLoginModal}
        setForceOpen={setOpenLoginModal}
        hideTrigger={true}
        setMenuOpen={setMenuOpen}
      />
    </div>
  )
}
