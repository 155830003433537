import Image from 'next/image'
import { useContext, useState } from 'react'
import AccountSettingsTabs from '@/app/(main)/profiles/[accountPublicKey]/components/AccountSettingsTabs'
import Modal from '@/components/Modal'
import PopUpContext from '@/contexts/PopUpContext'
import {
  Account,
  ButtonColor,
  ButtonFont,
  ButtonResponsiveness,
  ButtonVariant,
  ModalType,
  ProfileAuthorized,
} from '@/lib/types'
import { truncatePublicKey } from '@/lib/utils/truncatePublickey'
import Button from './tokens/Button'
import Icon from './tokens/Icon'

export default function ProfilePopUp({
  profile,
}: {
  profile: ProfileAuthorized | Account
}) {
  const [isOpen, setIsOpen] = useState(false)
  const { setShowPopUp, setForceOpen } = useContext(PopUpContext.Context)

  const closeCallback = () => {
    setShowPopUp(false)
    setIsOpen(false)
  }

  return (
    <>
      <div className="relative">
        <div className="flex flex-col">
          <div className="relative flex flex-row justify-between">
            <div>
              <Image
                src={'/assets/little-guy.png'}
                alt="little guy"
                className="h-[96px] w-[83px]"
                width={186}
                height={192}
              />
            </div>
            <button
              className="absolute right-0"
              onClick={() => setShowPopUp(false)}
            >
              <span>
                <Icon
                  name="closeLarge"
                  height={16}
                  width={16}
                  className="black h-16 w-16 md:hover:opacity-70"
                />
              </span>
            </button>
          </div>
          <p className="body-1 my-12">
            {'Welcome to Nina. Set up your profile to get started!'}
          </p>
          <div className="w-32">
            <Button
              color={ButtonColor.Blue}
              label={'Open Profile'}
              variant={ButtonVariant.Small}
              font={ButtonFont.Body1}
              textColor={ButtonColor.Blue}
              responsiveness={ButtonResponsiveness.MinW}
              onClick={() => {
                setIsOpen(true)
                setForceOpen(true)
              }}
            />
          </div>
        </div>
      </div>
      <p
        className="caption-1 absolute bottom-[-40px] left-0 cursor-pointer hover:opacity-80"
        onClick={() => setShowPopUp(false)}
      >
        Close
      </p>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalType={ModalType.Default}
        modalHeader={
          <p>
            Account Settings{' '}
            <span className="ml-2 text-grey-45">
              {truncatePublicKey(profile.publicKey)}
            </span>
          </p>
        }
        closeCallback={() => closeCallback()}
      >
        <AccountSettingsTabs
          profile={profile as ProfileAuthorized}
          setIsOpen={setIsOpen}
          profilePublicKey={profile.publicKey}
        />
      </Modal>
    </>
  )
}
