'use client'

import clsx from 'clsx'
import { memo, useContext, useEffect, useMemo, useState } from 'react'
import HubListItem from '@/components/HubListItem'
import Update from '@/contexts/UpdateContext'
import { fetchAccountFollowers } from '@/lib/account/fetchAccountFollowers'
import { fetchAccountFollowing } from '@/lib/account/fetchAccountFollowing'
import { fetchCollectorsForRelease } from '@/lib/account/fetchCollectorsForRelease'
import { fetchHubFollowers } from '@/lib/account/fetchHubFollowers'
import { fetchFavoritesForRelease } from '@/lib/favorite/fetchFavoritesForRelease'
import {
  Account,
  Collector,
  Follower,
  Following,
  HeaderTotals,
  Hub,
  HubCollaborator,
} from '@/lib/types'
import AccountListItem from './AccountListItem'
import FollowListSkeleton from './FollowListSkeleton'

type FollowListProps = {
  limit?: number
  offset?: number
  handleOrPublicKey: string
  inModal?: boolean
  type?:
    | 'hubFollowers'
    | 'accountFollowers'
    | 'accountFollowing'
    | 'releaseCollectors'
    | 'releaseFavorites'
    | 'suggestedAccounts'
  setHeaderTotals?: React.Dispatch<React.SetStateAction<HeaderTotals>>
  gridOverride?: boolean
}
type FollowListType =
  | Account
  | Following
  | Follower
  | Collector
  | Hub
  | HubCollaborator

function FollowList({
  type,
  handleOrPublicKey,
  inModal = false,
  limit = 5,
  offset = 0,
  gridOverride = false,
  setHeaderTotals,
}: FollowListProps) {
  const [list, setList] = useState<FollowListType[]>([])
  const { date } = useContext(Update.Context)
  const [fetched, setFetched] = useState(false)

  const followListProperties = useMemo(() => {
    switch (type) {
      case 'hubFollowers': {
        return {
          fetch: fetchHubFollowers,
          property: 'followers',
          emptyContentMessage: 'This Hub has no followers.',
          accountListItemType: 'followers',

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any
      }
      case 'accountFollowers': {
        return {
          fetch: fetchAccountFollowers,
          property: 'followers',
          emptyContentMessage: 'This user has no followers.',
          accountListItemType: 'followers',

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any
      }
      case 'accountFollowing': {
        return {
          fetch: fetchAccountFollowing,
          property: 'following',
          emptyContentMessage: 'This user is not following any users or hubs.',
          accountListItemType: 'following',

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any
      }
      case 'releaseCollectors': {
        return {
          fetch: fetchCollectorsForRelease,
          property: 'collectors',
          emptyContentMessage: 'No one has collected this release yet.',
          accountListItemType: 'collectors',

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any
      }
      case 'releaseFavorites': {
        return {
          fetch: fetchFavoritesForRelease,
          property: 'favorites',
          emptyContentMessage: 'No one has favorited this release yet.',
          accountListItemType: 'favorites',

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any
      }
      case 'suggestedAccounts': {
        return {
          fetch: fetchAccountFollowing,
          property: 'following',
          emptyContentMessage: 'No suggested accounts found.',
          accountListItemType: 'following',

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any
      }
    }
  }, [type, handleOrPublicKey])

  const handleFetchFollowList = async () => {
    setFetched(false)
    const { fetch, property } = followListProperties
    try {
      const data = await fetch(handleOrPublicKey, {
        limit,
        offset,
      })

      if (setList) {
        setList(data[property])
      }

      setFetched(true)
      setHeaderTotals &&
        setHeaderTotals((prev) => ({ ...prev, [type as string]: data.total }))
    } catch (error) {
      console.error(error)
      setFetched(true)
    }
  }

  useEffect(() => {
    handleFetchFollowList()
  }, [handleOrPublicKey])

  useEffect(() => {
    if (type !== 'suggestedAccounts') {
      handleFetchFollowList()
    }
  }, [date, type])

  if (!fetched && inModal) {
    return <FollowListSkeleton />
  }

  if (fetched && list.length === 0) {
    return (
      <p className="body-1 text-grey-45">
        {followListProperties.emptyContentMessage}
      </p>
    )
  }

  let applyBorder: boolean

  if (list.length > 4 && list.length % 2 === 0) {
    applyBorder = true
  } else {
    applyBorder = false
  }

  const secondToLastItem = list.length - 2

  // TODO: loading state
  return (
    <ul
      className={clsx(
        `grid-flow-cols grid grid-cols-1 gap-x-28  md:grid-rows-4 md:gap-x-20`,
        !gridOverride && list.length > 4 ? 'md:grid-cols-2' : 'md:grid-cols-1',
        inModal && list.length > 8 && 'pb-[92px] md:pb-0',
      )}
    >
      {list.length > 0 &&
        list.map((item, index) => {
          return (
            <li
              key={index}
              className={clsx(
                'flex border-b-1 border-grey-10 last-of-type:border-none',
                applyBorder && index === secondToLastItem && 'md:border-none',
              )}
            >
              {type === 'accountFollowing' && 'hub' in item ? (
                <HubListItem
                  hub={item.hub as Hub}
                  inModal={inModal}
                  itemWidthOverride={gridOverride}
                />
              ) : (
                <AccountListItem
                  item={
                    item as
                      | Account
                      | Following
                      | Follower
                      | Collector
                      | HubCollaborator
                  }
                  key={index}
                  type={followListProperties.accountListItemType}
                  inModal={inModal}
                  itemWidthOverride={gridOverride}
                />
              )}
            </li>
          )
        })}
    </ul>
  )
}

export default memo(FollowList)
