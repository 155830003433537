import Image, { ImageProps } from 'next/image'
import React from 'react'

const ImageWithArweaveFallback = (props: ImageProps) => {
  let { src } = props
  const { ...rest } = props

  if (typeof src === 'string') {
    src = (src as string)
      .replace('www.', '')
      .replace('arweave.net', 'gateway.irys.xyz')
  }

  const imageSource = src

  return (
    <Image
      {...rest}
      src={imageSource}
      onError={(event) => {
        event.currentTarget.id = `${imageSource}?fallback=true`
        event.currentTarget.src = `${imageSource}?fallback=true`
        event.currentTarget.srcset = `${imageSource}?fallback=true`
      }}
    />
  )
}

export default ImageWithArweaveFallback
