import { Account, SearchResultsAccount } from '../types'
import { AccountDisplayType, displayForAccount } from './displayForAccount'
import { truncatePublicKey } from './truncatePublickey'

export default function formatAccountData(
  account: Account | SearchResultsAccount,
) {
  const isSearchResult = account && 'displayName' in account

  const accountName =
    isSearchResult && account?.displayName
      ? account.displayName || truncatePublicKey(account.publicKey)
      : displayForAccount(account as Account, AccountDisplayType.Name)

  const accountImageString =
    isSearchResult && account?.image && account?.displayName
      ? account.image || '/assets/empty-user.png'
      : displayForAccount(account as Account, AccountDisplayType.Image)

  return {
    isSearchResult,
    accountName,
    accountImageString,
  }
}
