'use client'

import clsx from 'clsx'
import { useEffect, useState } from 'react'

export default function AppIndicator() {
  const [scrollY, setScrollY] = useState(0)
  const [showCongestionIndicator, setShowCongestionIndicator] = useState(true)
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY)
    }

    if (window !== undefined) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (scrollY > 5) {
      setShowCongestionIndicator(false)
    }

    if (scrollY === 0) {
      setShowCongestionIndicator(true)
    }
  }, [scrollY])

  return (
    <div
      className={clsx(
        'default-font caption-1 duration-400 col-start-1 col-end-[-1] flex w-[100vw] transform  flex-wrap justify-center bg-blue-82 text-center text-white transition-transform lg:ml-0 lg:mr-[-28px] ',
        showCongestionIndicator
          ? 'translate-y-[0%] p-12 lg:p-8'
          : '!h-[0] translate-y-[-100%]',
      )}
    >
      <span className="mr-4">
        The Nina mobile app is now available on iOS.
        <a
          href="https://ninaprotocol.com/app"
          className="ml-4 inline-block underline hover:opacity-80"
        >
          Download from the App Store
        </a>
        <span>.</span>
      </span>
    </div>
  )
}
