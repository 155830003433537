import Image from 'next/image'
import React, { useContext, useState } from 'react'
import PopUpContext from '@/contexts/PopUpContext'
import {
  ButtonColor,
  ButtonFont,
  ButtonResponsiveness,
  ButtonVariant,
} from '@/lib/types'
import SuggestedFollows from './SuggestedFollows'
import Button from './tokens/Button'
import Icon from './tokens/Icon'

export default function SuggestedFollowsPopUp() {
  const [isOpen, setIsOpen] = useState(false)
  const { setShowPopUp } = useContext(PopUpContext.Context)

  const closeCallback = () => {
    setIsOpen(false)
    setShowPopUp(false)
  }

  return (
    <div>
      <div className="relative">
        <div className="flex flex-col">
          <div className="relative flex flex-row justify-between">
            <div>
              <Image
                src={'/assets/little-guy.png'}
                alt="little guy"
                className="h-[96px] w-[83px]"
                width={186}
                height={192}
              />
            </div>
            <button
              className="absolute right-0"
              onClick={() => setShowPopUp(false)}
            >
              <span>
                <Icon
                  name="closeLarge"
                  height={16}
                  width={16}
                  className="black h-16 w-16 md:hover:opacity-70"
                />
              </span>
            </button>
          </div>
          <p className="body-1 my-12">
            {'Follow accounts and hubs to personalize your experience.'}
          </p>
          <div className="w-32">
            <Button
              color={ButtonColor.Blue}
              label={'See hubs and accounts to follow'}
              variant={ButtonVariant.Small}
              font={ButtonFont.Body1}
              textColor={ButtonColor.Blue}
              responsiveness={ButtonResponsiveness.MinW}
              onClick={() => {
                setIsOpen(true)
              }}
            />
          </div>
        </div>
      </div>
      <p
        className="caption-1 absolute bottom-[-40px] left-0 cursor-pointer hover:opacity-80"
        onClick={() => setShowPopUp(false)}
      >
        Close
      </p>
      <SuggestedFollows
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        hideTrigger={true}
        closeCallback={() => closeCallback()}
      />
    </div>
  )
}
