'use client'

import { Slider } from '@reach/slider'
import clsx from 'clsx'
import Link from 'next/link'
import { useContext, useState } from 'react'
import Audio from '@/contexts/AudioContext'
import { FavoriteType, TrackListType } from '@/lib/types'
import { formatDuration } from '@/lib/utils/formatDuration'
import AudioPlayerMobileControls from './AudioPlayerMobileControls'
import FavoriteButton from './FavoriteButton'
import ImageWithArweaveFallback from './ImageWithArweaveFallback'
import QueueList from './QueueList'
import ReleaseRepostModal from './ReleaseRepostModal'
import TrackTitleDisplay from './TrackTitleDispay'

type AudioPlayerDrawerProps = {
  showMobileListeningTabs: boolean
  setShowMobileListeningTabs: (value: boolean) => void
}

export default function AudioPlayerDrawer(props: AudioPlayerDrawerProps) {
  const { showMobileListeningTabs, setShowMobileListeningTabs } = props

  const {
    activeQueueItem,
    queue,
    activeIndex,
    clearQueue,
    duration,
    currentTime,
    handleSeek,
    drawerOpen,
    handleDrawerToggle,
  } = useContext(Audio.Context)

  const [activeTab, setActiveTab] = useState(0)

  const listeningTabs = [
    {
      title: 'Playing Next',
      content: <QueueList type={TrackListType.Queue} />,
      clearFunction: () => {
        clearQueue()
      },
    },
    // {
    //   title: 'History',
    //   content: <QueueList type={TrackListType.History} />,
    // },
  ]

  const handleTabSelect = (index: number) => {
    setActiveTab(index)
  }

  const release = activeQueueItem()?.release

  return (
    <>
      <div
        className={clsx(
          'fixed bottom-0 w-full !font-sans',
          drawerOpen && ' h-[100dvh]',
        )}
        onClick={() => {
          handleDrawerToggle()
        }}
      ></div>
      <div
        className={clsx(
          'linear blueTheme fixed bottom-0 w-full overflow-x-hidden overflow-y-scroll bg-blue-82 transition-[transform] duration-[270ms] xs:h-full md:bottom-[64px] md:h-[536px] md:max-h-[536px] md:overflow-y-hidden  md:duration-[250ms]',
          drawerOpen
            ? 'z-[10] translate-y-0'
            : 'translate-y-[125dvh] md:translate-y-[536px]',
        )}
      >
        <div className=" default-font grid h-full max-h-[calc(100%-64px)] gap-x-[28px] px-12 pb-12 pt-12 text-white md:h-[496px] lg:grid-cols-2">
          <div className="z-[10] flex gap-20 xs:flex-col md:flex-row lg:flex-col lg:gap-0">
            <h1 className="ui-1 mb-28 hidden uppercase lg:block">
              Now Playing
            </h1>
            {activeQueueItem() && (
              <Link
                href={`/releases/${
                  activeQueueItem()!.release.slug ||
                  activeQueueItem()!.release.publicKey
                }`}
                className="md:w-fit"
                onClick={() => {
                  handleDrawerToggle()
                }}
              >
                <ImageWithArweaveFallback
                  src={activeQueueItem()!.release.metadata.image}
                  alt={activeQueueItem()!.release.metadata.properties.title}
                  height={500}
                  width={500}
                  className="sm:first-letter imageBorder w-full max-w-[322px] xs:mx-auto  xs:max-w-none sm:mx-0 sm:h-[180px] sm:w-[180px] sm:border-blue-82"
                />{' '}
              </Link>
            )}
            <div className="display-1 relative w-full max-w-[100vw] overflow-hidden lg:mt-20">
              {activeQueueItem() && (
                <>
                  <Link
                    href={`/releases/${
                      activeQueueItem()!.release.slug ||
                      activeQueueItem()!.release.publicKey
                    }`}
                    className="block w-fit"
                    onClick={() => {
                      handleDrawerToggle()
                    }}
                  >
                    {activeQueueItem() && (
                      <div className="display-1 block max-w-none">
                        <TrackTitleDisplay
                          queueItem={activeQueueItem()!}
                          type="drawer"
                        />
                      </div>
                    )}
                  </Link>
                  {activeQueueItem()!.release.hub && (
                    <Link
                      href={`/hubs/${activeQueueItem()!.release.hub!.handle}`}
                      className="block w-fit"
                      onClick={() => {
                        handleDrawerToggle()
                      }}
                    >
                      <p className="blue-title text-blue-35">
                        {activeQueueItem()!.release.hub!.data.displayName}
                      </p>
                    </Link>
                  )}
                  {!activeQueueItem()?.release.hub && (
                    <Link
                      href={`/profiles/${
                        activeQueueItem()!.release.publisherAccount?.handle
                      }`}
                    >
                      <p className="blue-title text-blue-35">
                        {
                          activeQueueItem()!.release.publisherAccount
                            ?.displayName
                        }
                      </p>
                    </Link>
                  )}
                  {activeQueueItem() && (
                    <div className="  z-10 mt-[20px]  hidden w-min space-x-16  bg-blue-82  sm:flex ">
                      <div className="z-1 hidden space-x-12 bg-blue-82 sm:flex">
                        <abbr title={'Repost release'} className="no-underline">
                          <ReleaseRepostModal
                            release={release!}
                            variant={'drawer'}
                          />
                        </abbr>
                      </div>

                      <div className="m-auto flex h-[40px] ">
                        <FavoriteButton
                          iconColor="white"
                          publicKey={activeQueueItem()!.release.publicKey}
                          favoriteType={FavoriteType.Release}
                          styleOverride="flex m-auto"
                          inAudioPlayer={true}
                          title={activeQueueItem()!.release.metadata.name}
                          source={'audioPlayer'}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            {/* xs progress in drawer*/}
            <div className="mobile_audio_controls body-1 progress mb-20 block items-center text-white sm:hidden">
              <div className="mb-8 cursor-pointer ">
                <Slider
                  value={currentTime}
                  min={0}
                  max={duration}
                  onChange={handleSeek}
                />
              </div>
              <div className="flex w-full justify-between text-blue-35 ">
                <p className="ui-1-tabular">{formatDuration(currentTime)}</p>
                <p className="ui-1-tabular">-{formatDuration(duration)}</p>
              </div>
            </div>
            {activeQueueItem() && (
              <div className="hidden sm:block ">
                <AudioPlayerMobileControls
                  setShowMobileListeningTabs={setShowMobileListeningTabs}
                  showMobileListeningTabs={showMobileListeningTabs}
                  publicKey={activeQueueItem()?.release.publicKey}
                />
              </div>
            )}
          </div>

          {/* sm up:listening tabs */}
          <div className=" z-[6] mt-40 hidden   sm:block lg:mt-0">
            <div className="relative mb-[11px] flex ">
              {listeningTabs.map((tab, index) => (
                <div
                  key={index}
                  className="cursor-pointer pr-16 "
                  onClick={() => handleTabSelect(index)}
                >
                  <h1
                    className={clsx(
                      '!ui-1 pb-4 uppercase',
                      activeTab === index && 'border-b-1 border-white',
                    )}
                  >
                    {tab.title}
                  </h1>
                </div>
              ))}

              {listeningTabs[activeTab].clearFunction &&
                queue.length - (activeIndex + 1) > 0 && (
                  <button
                    className="ui-1 absolute right-0 pb-4 uppercase text-blue-35"
                    onClick={listeningTabs[activeTab]?.clearFunction}
                  >
                    clear all ({queue.length - (activeIndex + 1)})
                  </button>
                )}
            </div>
            {queue.length === 1 && (
              <p className="body-1 text-blue-35">
                There are no tracks in the queue.
              </p>
            )}
            <div className="bg-blue-82">{listeningTabs[activeTab].content}</div>
          </div>
        </div>
      </div>

      {drawerOpen && (
        <div className=" fixed bottom-0 z-[12] w-full">
          {/* xs listening tabs */}
          {showMobileListeningTabs && (
            <div className="fixed bottom-0 z-[-1] mt-[12px] block h-[100dvh] w-full   bg-blue-82 px-12 pt-12 sm:hidden ">
              <div className="">
                <div className="mb-28 flex w-full justify-between">
                  <div className="flex">
                    {listeningTabs.map((tab, index) => (
                      <div
                        key={index}
                        className="cursor-pointer pr-16"
                        onClick={() => handleTabSelect(index)}
                      >
                        <h1
                          className={clsx(
                            'ui-1 pb-4 uppercase text-white',
                            activeTab === index && 'border-b-1 border-white ',
                          )}
                        >
                          {tab.title}
                        </h1>
                      </div>
                    ))}
                  </div>

                  {listeningTabs[activeTab].clearFunction &&
                    queue.length - (activeIndex + 1) > 0 && (
                      <button
                        className="ui-1 right-0 pb-4 uppercase text-blue-35"
                        onClick={listeningTabs[activeTab]?.clearFunction}
                      >
                        clear all ({queue.length - (activeIndex + 1)})
                      </button>
                    )}
                </div>
                {listeningTabs[activeTab].content}
              </div>
            </div>
          )}

          <div className="block w-full px-12 sm:hidden">
            <AudioPlayerMobileControls
              setShowMobileListeningTabs={setShowMobileListeningTabs}
              showMobileListeningTabs={showMobileListeningTabs}
              publicKey={activeQueueItem()?.release.publicKey}
            />
          </div>
          <button
            className={
              'ui-1 z-[501] h-[64px] w-full bg-blue-82 p-10 text-white xs:block md:hidden'
            }
            onClick={() => handleDrawerToggle()}
          >
            CLOSE
          </button>
        </div>
      )}
    </>
  )
}
