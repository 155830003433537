'use client'

import { memo } from 'react'
import { ButtonColor, ButtonFont, ButtonVariant, ModalType } from '@/lib/types'
import FollowList from './FollowList'
import Modal from './Modal'

type SuggestedFollowsProps = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  hideTrigger?: boolean
  closeCallback?: () => void
}

const SUGGESTED_FOLLOWS_PUBLIC_KEY =
  '9aXf8JdNpcB1aanxvij12LzNG8ZdTQ3Rk7v7vhGmnU8h'

function SuggestedFollows({
  isOpen,
  setIsOpen,
  hideTrigger,
  closeCallback,
}: SuggestedFollowsProps) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalType={ModalType.ViewAll}
        label={'See Accounts to follow'}
        modalLabelVariant={ButtonVariant.Small}
        modalLabelTextColor={ButtonColor.Black}
        modalLabelFont={ButtonFont.Body1}
        modalHeader={'Accounts to follow'}
        hideTrigger={hideTrigger}
        closeCallback={closeCallback}
      >
        <div className="md:pb-28">
          <FollowList
            handleOrPublicKey={SUGGESTED_FOLLOWS_PUBLIC_KEY}
            limit={1000}
            type={'suggestedAccounts'}
            inModal={true}
            gridOverride={true}
          />
        </div>
      </Modal>
    </>
  )
}

export default memo(SuggestedFollows)
