'use client'

import { useContext, useEffect, useState } from 'react'
import Toast from '@/contexts/ToastContext'
import Update from '@/contexts/UpdateContext'
import Wallet from '@/contexts/WalletContext'
import { WalletContextValues } from '@/contexts/WalletContext'
import { PublicKeyString } from '@/lib/types'
import { subscriptionSubscribeDelegated } from '@/lib/utils/subscriptionSubscribeDelegated'
import { subscriptionUnsubscribeDelegated } from '@/lib/utils/subscriptionUnsubscribeDelegated'
import LoginModal from './LoginModal'

type FollowProps = {
  publicKey: PublicKeyString
  hubHandle?: string
  setIsItemSubmitting?: React.Dispatch<React.SetStateAction<boolean>>
  displayName?: string
  variant?: string
}

export default function Follow({
  publicKey,
  hubHandle,
  displayName,
  variant = 'default',
}: FollowProps) {
  const { userSubscriptions, sessionSignature } = useContext(
    Wallet.Context,
  ) as WalletContextValues

  const [subscribed, setSubscribed] = useState<boolean | undefined>(undefined)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [forceOpen, setForceOpen] = useState(false)
  const { update } = useContext(Update.Context)
  const { addToast } = useContext(Toast.Context)
  useEffect(() => {
    if (!userSubscriptions) return

    if (userSubscriptions.includes(publicKey)) {
      setSubscribed(true)
    } else {
      setSubscribed(false)
    }
  }, [publicKey, userSubscriptions])

  const handleSubscribe = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    setSubmitting(true)

    if (!sessionSignature) {
      throw new Error('session not found, please log out and reconnect')
    }

    if (subscribed) {
      const { success } = await subscriptionUnsubscribeDelegated({
        to: publicKey,
        sessionSignature: sessionSignature,
      })

      if (success) {
        setSubscribed(false)
        addToast({
          message: `You have unfollowed ${displayName}`,
          variant: 'success',
        })
      } else {
        addToast({
          message: `Error unfollowing ${displayName}`,
          variant: 'error',
        })
      }
    } else {
      const { success } = await subscriptionSubscribeDelegated({
        to: publicKey,
        type: hubHandle ? 'hub' : 'account',
        hubHandle,
        sessionSignature: sessionSignature,
      })

      if (success) {
        setSubscribed(true)
        addToast({
          message: `You have followed ${displayName}`,
          variant: 'success',
        })
      } else {
        addToast({
          message: `Error following ${displayName}`,
          variant: 'error',
        })
      }
    }

    update()
    setSubmitting(false)
  }

  const handleFavorite = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault()
    e.stopPropagation()

    if (!sessionSignature) {
      setForceOpen(true)

      return
    } else {
      await handleSubscribe(e)
    }
  }

  if (sessionSignature?.publicKey && publicKey === sessionSignature?.publicKey)
    return null

  return (
    <>
      {variant === 'release' && (
        <button
          className={'caption-1 mt-12 w-fit text-grey-45'}
          onClick={(e) => handleFavorite(e)}
          disabled={submitting}
        >
          {submitting && (
            <p className="flex w-full">
              {subscribed
                ? ` Unfollowing ${displayName}`
                : `Following ${displayName}`}
              <span className="pending flex text-start"></span>
            </p>
          )}
          {!submitting && (
            <p className="flex w-full md:hover:opacity-80">
              {subscribed
                ? ` Unfollow ${displayName}`
                : `Follow ${displayName}`}
            </p>
          )}
        </button>
      )}
      {variant === 'default' && (
        <button
          className={'ui-1 text-grey-45'}
          onClick={(e) => handleFavorite(e)}
          disabled={submitting}
        >
          {submitting && (
            <p className="flex w-full">
              {subscribed ? ' Unfollowing' : 'Following'}
              <span className="pending flex text-start"></span>
            </p>
          )}
          {!submitting && (
            <p className="flex w-full md:hover:opacity-80">
              {subscribed ? ' Unfollow' : 'Follow'}
            </p>
          )}
        </button>
      )}
      <LoginModal
        forceOpen={forceOpen}
        setForceOpen={setForceOpen}
        hideTrigger={true}
        loginModalLabelPromptString={`Create an account or sign in to follow this ${
          hubHandle ? 'hub' : 'account'
        }.`}
      />
    </>
  )
}
