import { Hub, SearchResultsHub } from '../types'

export default function formatHubData(hub: Hub | SearchResultsHub) {
  const isSearchResult = hub && 'displayName' in hub
  const hubName = isSearchResult ? hub.displayName : hub.data.displayName
  const hubImageString = isSearchResult ? hub.image : hub.data.image
  const searchResultDate = new Date(hub.accountData?.hub?.datetime || '')
  const hubImageDateString = isSearchResult ? searchResultDate : hub.datetime

  return {
    isSearchResult,
    hubName,
    hubImageString,
    hubImageDateString,
  }
}
