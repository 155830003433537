'use client'

import clsx from 'clsx'
import Link from 'next/link'
import { memo } from 'react'
import { Hub, SearchResultsHub } from '@/lib/types'
import ImagePlaceholderBase64 from '@/lib/utils/ImagePlaceholderBase64'
import formatHubData from '@/lib/utils/formatHubData'
import Follow from './Follow'
import ImageWithArweaveFallbackClient from './ImageWithArweaveFallbackClient'

type HubListItemProps = {
  hub: Hub | SearchResultsHub
  key?: string
  inContentPage?: boolean
  inModal?: boolean
  itemWidthOverride?: boolean
}

function HubListItem({
  hub,
  key,
  inContentPage = false,
  inModal = false,
  itemWidthOverride = false,
}: HubListItemProps) {
  const { hubImageString, hubName } = formatHubData(hub)

  const noImage =
    hubImageString === 'https://arweave.net/' ||
    hubImageString === 'https://arweave.net/undefined' ||
    hubImageString === '' ||
    !hubImageString

  if (!hub) return null

  return (
    <li
      className={clsx(
        'body-1 group flex w-full justify-between py-12',
        inContentPage && 'border-b border-grey-15 last-of-type:border-none',
      )}
      key={key}
    >
      <div className="flex items-center">
        <Link href={`/hubs/${hub?.handle}`}>
          {noImage && (
            <div className="mr-12 ">
              <ImageWithArweaveFallbackClient
                alt={hubName}
                className="imageBorder h-[40px] w-full min-w-[40px] max-w-[40px]"
                src={'/assets/empty-user.png'}
                height={40}
                width={40}
                placeholder="blur"
                blurDataURL={ImagePlaceholderBase64}
              />
            </div>
          )}
          {!noImage && (
            <div className="mr-12 ">
              <ImageWithArweaveFallbackClient
                alt={hubName}
                className="imageBorder h-[40px] w-full min-w-[40px] max-w-[40px]"
                src={hubImageString}
                height={40}
                width={40}
                placeholder="blur"
                blurDataURL={ImagePlaceholderBase64}
              />
            </div>
          )}
        </Link>

        <Link
          href={`/hubs/${hub?.handle}`}
          className={clsx(
            'body-1 line-clamp-1 overflow-hidden text-blue-82 hover:text-opacity-80',
            itemWidthOverride && 'w-full',
            !itemWidthOverride &&
              inModal &&
              'w-full md:w-[140px] md:break-words',
          )}
        >
          {hubName}
        </Link>
      </div>
      <div className="flex">
        <Follow
          publicKey={hub?.publicKey}
          hubHandle={hub.handle}
          displayName={hub?.data?.displayName || ''}
        />
      </div>
    </li>
  )
}

export default memo(HubListItem)
