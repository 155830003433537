'use client'

import { Slider, SliderOrientation } from '@reach/slider'
import '@reach/slider/styles.css'
import clsx from 'clsx'
import Link from 'next/link'
import { useContext } from 'react'
import { useState } from 'react'
import Audio from '@/contexts/AudioContext'
import Wallet from '@/contexts/WalletContext'
import { FavoriteType } from '@/lib/types'
import ImagePlaceholderBase64 from '@/lib/utils/ImagePlaceholderBase64'
import { logEvent } from '@/lib/utils/event'
import { formatDuration } from '@/lib/utils/formatDuration'
import { detectSmallDown } from '@/lib/utils/useScreenWidth'
import AudioPlayerDrawer from './AudioPlayerDrawer'
import FavoriteButton from './FavoriteButton'
import ImageWithArweaveFallback from './ImageWithArweaveFallback'
import TrackTitleDisplay from './TrackTitleDispay'
import Icon from './tokens/Icon'

export default function AudioPlayer() {
  const {
    audioPlayerRef,
    duration,
    currentTime,
    isPlaying,
    playNextQueueItem,
    playPreviousQueueItem,
    pauseResume,
    handleSeek,
    handleDrawerToggle,
    drawerOpen,
    activeItem,
  } = useContext(Audio.Context)

  const { wallet } = useContext(Wallet.Context)
  const [volumeOpen, setVolumeOpen] = useState(false)
  const [muted, setMuted] = useState(false)
  const [volume, setVolume] = useState(100)
  const [prevVolume, setPrevVolume] = useState(100)
  const isSmallDown = detectSmallDown()
  const [showMobileListeningTabs, setShowMobileListeningTabs] = useState(false)

  const handleVolume = (value: number) => {
    if (value === 0) {
      setMuted(true)
    } else {
      setMuted(false)
    }

    setVolume(value)

    logEvent('volume_changed', 'interaction', wallet)

    if (audioPlayerRef.current) {
      audioPlayerRef.current.volume = value / 100
    }
  }

  const handleVolumeClose = () => {
    setTimeout(() => {
      setVolumeOpen(false)
    }, 0)
  }

  const handleMute = () => {
    setMuted(true)
    setPrevVolume(volume)
    handleVolume(0)
  }

  const handleUnmute = () => {
    setMuted(false)
    handleVolume(prevVolume)
  }

  const handleMobileDrawerToggle = () => {
    if (isSmallDown) {
      handleDrawerToggle()

      if (showMobileListeningTabs) {
        setShowMobileListeningTabs(false)
      }
    }
  }

  const handlePauseResume = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    logEvent('track_play_pause_button_toggled', 'interaction', wallet, {
      publicKey: activeItem?.release.publicKey,
    })

    pauseResume()
  }

  return (
    <div className="font-sans">
      <audio ref={audioPlayerRef} />
      <AudioPlayerDrawer
        showMobileListeningTabs={showMobileListeningTabs}
        setShowMobileListeningTabs={setShowMobileListeningTabs}
      />
      <div
        onClick={handleMobileDrawerToggle}
        className={clsx(
          ` fixed bottom-0 z-[499] flex h-[64px] w-full items-center justify-between gap-x-[28px]  bg-white p-12`,
          drawerOpen && 'xs:z-auto sm:z-[499]', // switch Z to show 'close' cta on mobile player
        )}
      >
        <div className=" flex overflow-x-hidden md:w-1/2  lg:w-1/2  lg:max-w-[calc(100vw_-_80px)]">
          {activeItem && (
            <div className="pointer-events-none flex max-w-full sm:pointer-events-auto">
              <Link
                href={`/releases/${
                  activeItem.release.slug || activeItem.release.publicKey
                }`}
                className="h-40 w-40 min-w-[40px]"
              >
                <ImageWithArweaveFallback
                  src={activeItem.release.metadata.image}
                  alt={activeItem.release.metadata.properties.title}
                  height={40}
                  width={40}
                  placeholder="blur"
                  blurDataURL={ImagePlaceholderBase64}
                  className=" imageBorderPlayer h-[40px] w-[40px]"
                />
              </Link>

              <div className="default-font body-1 ml-12 max-w-[100%] overflow-x-hidden truncate ">
                <Link
                  href={`/releases/${
                    activeItem.release.slug || activeItem.release.publicKey
                  }`}
                  className="hover:opacity-80"
                >
                  <TrackTitleDisplay queueItem={activeItem} type="player" />
                </Link>

                {activeItem.release.hub && (
                  <div className="w-min truncate">
                    <Link
                      href={`/hubs/${activeItem.release.hub!.handle}`}
                      className="text-blue-82 hover:opacity-80"
                    >
                      {activeItem.release.hub!.data.displayName}
                    </Link>
                  </div>
                )}
                {!activeItem.release.hub && (
                  <div className="w-min truncate ">
                    <Link
                      href={`/profiles/${activeItem.release?.publisherAccount?.handle}`}
                      className="text-grey-45 hover:opacity-80"
                    >
                      {activeItem.release?.publisherAccount?.displayName}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="default-font flex items-center md:w-1/2 md:justify-between ">
          <div className="ui-1 progress hidden items-center text-blue-82 md:flex md:w-full">
            <p className="ui-1-tabular text-left">
              {formatDuration(currentTime)}
            </p>

            <div className="mx-12 cursor-pointer md:w-full">
              <Slider
                value={currentTime}
                min={0}
                max={duration}
                onChange={handleSeek}
              />
            </div>
            <p className="ui-1-tabular w-[60px] text-left tabular-nums	">
              {formatDuration(duration)}
            </p>
          </div>

          <div className="flex ">
            <div className="mx-0 flex items-center sm:mx-[16px] md:mx-[20px] lg:mx-[28px] xl:mx-[40px] ">
              {activeItem && (
                <div className="mr-0 block p-10 sm:mr-[20px] sm:p-0 md:hidden">
                  <FavoriteButton
                    publicKey={activeItem?.release.publicKey || ''}
                    favoriteType={FavoriteType.Release}
                    source={'audioPlayer'}
                    iconColor="blue-82"
                  />
                </div>
              )}

              <button
                onClick={() => {
                  logEvent(
                    'track_play_previous_track_button_clicked',
                    'interaction',
                    wallet,
                    {
                      publicKey: activeItem?.release.publicKey,
                    },
                  )

                  playPreviousQueueItem()
                }}
                className="p-y4 hidden md:block"
              >
                <Icon name="previous" className="blue-82 hover:opacity-80" />
              </button>

              <button
                onClick={(e) => handlePauseResume(e)}
                className="hitBox40 mx-0 px-[10px] py-[10px] hover:opacity-80 sm:px-0 sm:py-4 md:mx-12"
              >
                {isPlaying ? (
                  <Icon
                    name="pauseLarge"
                    className="blue-82 hover:opacity-80"
                  />
                ) : (
                  <Icon name="playLarge" className="blue-82 hover:opacity-80" />
                )}
              </button>

              <button
                onClick={() => {
                  logEvent(
                    'track_play_next_track_button_clicked',
                    'interaction',
                    wallet,
                    {
                      publicKey: activeItem?.release.publicKey,
                    },
                  )

                  playNextQueueItem()
                }}
                className="hidden py-4 md:block"
              >
                <Icon name="next" className="blue-82 hover:opacity-80" />
              </button>
            </div>
            <div className="mr-0 hidden py-4 md:mr-16 md:block">
              <FavoriteButton
                publicKey={activeItem?.release.publicKey || ''}
                favoriteType={FavoriteType.Release}
                source={'audioPlayer'}
                iconColor="blue-82"
              />
            </div>
            <div
              onMouseEnter={() => {
                setVolumeOpen(true)
              }}
              onMouseLeave={() => handleVolumeClose()}
              className="volume relative hidden flex-col sm:flex"
            >
              <div
                onMouseOver={() => {
                  setVolumeOpen(true)
                }}
                className={clsx(
                  ' absolute bottom-[26px] m-auto flex w-[20px]  bg-white transition-all duration-0',
                  volumeOpen ? 'h-[120px]' : 'h-0',
                )}
              >
                <Slider
                  min={0}
                  max={100}
                  orientation={SliderOrientation.Vertical}
                  className="m-auto py-12"
                  onChange={handleVolume}
                  defaultValue={100}
                  value={muted ? 0 : volume}
                />
              </div>

              <button
                className="mr-0 py-4 md:mr-16 "
                onClick={!muted ? handleMute : handleUnmute}
              >
                {muted && <Icon name="volumeMuted" className="blue-82" />}
                {!muted && <Icon name="volume" className="blue-82 " />}
              </button>
            </div>

            <button
              onClick={(e) => {
                e.stopPropagation()
                handleDrawerToggle()
              }}
              className="hidden p-0 md:block"
            >
              <Icon name="queue" className="blue-82 hover:opacity-80" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
