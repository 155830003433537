'use client'

import clsx from 'clsx'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useCallback, useContext, useEffect, useState } from 'react'
import PopUp from '@/contexts/PopUpContext'
import Wallet from '@/contexts/WalletContext'
import { fetchNotifications } from '@/lib/notifications/fetchNotifications'
import { postNotificationsViewed } from '@/lib/notifications/postNotificationsViewed'
import {
  ButtonColor,
  ButtonFont,
  ButtonVariant,
  FetchNotificationsResponse,
} from '@/lib/types'
import NotificationsList from './NotificationsList'
import Button from './tokens/Button'

type NotificationsModalProps = {
  view: 'mobile' | 'desktop'
  modalLabelFont: ButtonFont
}

const EVERY_MINUTE = 60000

export default function NotificationsModal({
  view,
  modalLabelFont,
}: NotificationsModalProps) {
  const pathname = usePathname()
  const { wallet, sessionSignature } = useContext(Wallet.Context)
  const { showPopUp } = useContext(PopUp.Context)
  const [isOpen, setIsOpen] = useState(false)
  const [showLabel, setShowLabel] = useState(false)

  const [notifications, setNotifications] = useState<
    FetchNotificationsResponse | undefined
  >()

  const [notificationsModalLabel, setNotificationsModalLabel] =
    useState<string>('')

  useEffect(() => {
    const interval = setInterval(() => {
      handleFetchNotifications()
    }, EVERY_MINUTE)

    return () => clearInterval(interval)
  }, [wallet, sessionSignature])

  useEffect(() => {
    if (wallet && wallet.publicKey && sessionSignature) {
      handleFetchNotifications()
      setShowLabel(true)
    } else {
      setShowLabel(false)
    }
  }, [wallet.publicKey, sessionSignature])

  useEffect(() => {
    if (view === 'mobile') {
      // if unviewed notifications, show number of notifications
      // if no unviewed notifications, do not show number of notifications
      setNotificationsModalLabel(
        isOpen
          ? 'Close'
          : `${
              notifications?.unviewed && notifications.unviewed > 0
                ? `${notifications?.unviewed}`
                : ''
            }`,
      )
    } else {
      setNotificationsModalLabel(
        isOpen
          ? 'Close'
          : `Notifications ${
              notifications?.unviewed ? `(${notifications?.unviewed})` : ''
            }`,
      )
    }
  }, [isOpen, notifications])

  useEffect(() => {
    if (!isOpen && notifications) {
      setNotifications((prevNotifications) => ({
        ...(prevNotifications as FetchNotificationsResponse),
        unviewed: 0,
      }))
    }
  }, [isOpen])

  const handleFetchNotifications = useCallback(async () => {
    if (!wallet.publicKey) return

    try {
      const notificationResponse = await fetchNotifications()

      if (notificationResponse) {
        setNotifications(notificationResponse)
      }
    } catch (error) {
      console.error('Error fetching notifications:', error)
    }
  }, [wallet.publicKey])

  const handleNotificationsClick = () => {
    if (!wallet.publicKey) return

    setIsOpen(!isOpen)
    postNotificationsViewed()
  }

  if (pathname === '/notifications') return null

  if (
    notifications &&
    notifications?.notificationItems.length === 0 &&
    !showPopUp
  )
    return (
      <Link
        href={'/notifications'}
        className={clsx(
          view === 'desktop' &&
            'fixed bottom-[92px] left-[28px] hover:opacity-80',
          view === 'mobile' &&
            'flex  items-center justify-start border-none bg-none px-0 py-0 text-blue-82 ',
          modalLabelFont,
        )}
      >
        {notificationsModalLabel}
      </Link>
    )

  return (
    <div className="relative">
      {showLabel && !showPopUp && (
        <>
          <div
            className={clsx(
              view === 'desktop' && 'fixed bottom-[92px] left-[28px]',
            )}
          >
            <Button
              label={notificationsModalLabel}
              color={ButtonColor.Unstyled}
              variant={ButtonVariant.Unstyled}
              font={modalLabelFont}
              onClick={handleNotificationsClick}
              textColor={
                view === 'mobile' ? ButtonColor.Blue : ButtonColor.Black
              }
            />
          </div>
          {isOpen && (
            <div className="relative flex h-full w-full items-center justify-center">
              <div className="no-scrollbar fixed bottom-[64px] left-12 max-h-[calc(100dvh_-_108px)] w-[calc(100vw_-_24px)] overflow-auto border-1 border-grey-15 bg-white pt-12 md:!max-h-[calc(100vh_-_210px)] md:w-[376px] lg:bottom-[136px] lg:left-28 ">
                {notifications &&
                  notifications?.notificationItems?.length > 0 && (
                    <div className="flex h-full flex-col">
                      <div className="flex-grow px-12">
                        <NotificationsList
                          notifications={notifications?.notificationItems || []}
                          onNotificationClick={() => setIsOpen(false)}
                          isNotificationPage={false}
                        />
                      </div>
                      <div className="sticky bottom-[0px] z-[8]">
                        <Link
                          href="/notifications"
                          onClick={() => setIsOpen(false)}
                        >
                          <button className="ui-1  text-16 flex h-[64px] w-full items-center justify-center border-none bg-white py-[24px] text-grey-45 ">
                            <p className="hover:opacity-70">
                              View all notifications
                            </p>
                          </button>
                        </Link>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
