'use client'

import Image, { ImageProps } from 'next/image'
import { useCallback, useEffect, useState } from 'react'

const ImageWithFallback = (props: ImageProps) => {
  const { src, ...rest } = props
  const [imageSrc, setImageSrc] = useState(src)

  const handleImageSrc = useCallback(() => {
    setImageSrc(src)
  }, [src])

  useEffect(() => {
    handleImageSrc()
  }, [handleImageSrc])

  return (
    <>
      <Image
        {...rest}
        src={imageSrc}
        onError={() => {
          setImageSrc('/assets/empty-user.png')
        }}
      />
    </>
  )
}

export default ImageWithFallback
