import Nina from '@nina-protocol/js-sdk'
import { cache } from 'react'
import initSdk from '@/lib/utils/initSdk'
import { PaginationProps, PublicKeyString } from '../types'

export const fetchAccountFollowers = cache(
  async (
    accountPublicKey: PublicKeyString,
    pagination: PaginationProps = { limit: 20, offset: 0, sort: 'desc' },
  ) => {
    await initSdk()

    const followersResponse = await Nina.Account.fetchFollowers(
      accountPublicKey,
      pagination,
    )

    return followersResponse
  },
)
