import Nina from '@nina-protocol/js-sdk'
import axios from 'axios'
import { PublicKeyString } from '../types'
import initSdk from './initSdk'
import { ninaErrorHandler } from './ninaErrorHandler'

type SubscriptionSubscribeDelegatedProps = {
  to: PublicKeyString
  type: 'account' | 'hub'
  hubHandle?: string
  sessionSignature: {
    message: string
    signature: string
    publicKey: string
  }
}

interface ErrorResponse {
  response?: {
    data: {
      error: string
    }
  }
  msg?: string
}

type FollowParams = {
  to: PublicKeyString
  signature: string
  message: string
  publicKey: string
  type: 'account' | 'hub'
  hubHandle?: string
}

export const subscriptionSubscribeDelegated = async ({
  to,
  type,
  hubHandle,
  sessionSignature,
}: SubscriptionSubscribeDelegatedProps) => {
  const { message, publicKey, signature } = sessionSignature

  if (!sessionSignature) {
    return {
      success: false,
      msg: 'Failed to Unfollow: Session Signature is null',
    }
  }

  try {
    await initSdk()

    const params: FollowParams = {
      to,
      signature: signature,
      message: message,
      publicKey: publicKey,
      type,
    }

    if (hubHandle) {
      params.hubHandle = hubHandle
    }

    const { status, txid, subscription } = (
      await axios.post(`${process.env.NINA_ID_ENDPOINT}/follow`, params)
    ).data

    if (status === 'success') {
      if (txid) {
        await Nina.confirmTransaction(txid)
        await axios.get(
          `${process.env.NINA_API_ENDPOINT}/subscriptions/${subscription}?transactionId=${txid}`,
        )
      }

      return {
        success: true,
        msg: 'Now Following',
      }
    } else {
      return {
        success: false,
        msg: 'Failed to Follow',
      }
    }
  } catch (error) {
    console.warn(error)
    const errorResponse = error as ErrorResponse

    return ninaErrorHandler(errorResponse)
  }
}
