import clsx from 'clsx'
import Link from 'next/link'
import { memo } from 'react'
import {
  Account,
  Collector,
  Follower,
  Following,
  Hub,
  HubCollaborator,
  SearchResultsAccount,
} from '@/lib/types'
import formatAccountData from '@/lib/utils/formatAccountData'
import Follow from './Follow'
import ImageWithFallback from './ImageWithFallback'

type AccountListItemProps = {
  item:
    | Account
    | Follower
    | Following
    | Collector
    | Hub
    | HubCollaborator
    | SearchResultsAccount
  type?:
    | 'followers'
    | 'following'
    | 'collectors'
    | 'collaborators'
    | 'favorites'
  disableLink?: boolean
  inModal?: boolean
  itemWidthOverride?: boolean
}

function AccountListItem({
  item,
  type,
  disableLink = false,
  inModal = false,
  itemWidthOverride = false,
}: AccountListItemProps) {
  let account: Account | SearchResultsAccount | undefined
  let accountImageString
  let accountName

  switch (type) {
    case 'collectors':
      account = item as Collector

      if (account) {
        const { accountName: name, accountImageString: image } =
          formatAccountData(account)

        accountImageString = image
        accountName = name
      }

      break
    case 'followers':
    case 'following':
    case 'favorites':
      account =
        'account' in item ? (item as Follower | Following).account : undefined

      if (account) {
        const { accountName: name, accountImageString: image } =
          formatAccountData(account)

        accountImageString = image
        accountName = name
        break
      }
    default:
      account = item as Account

      if (account) {
        const { accountName: name, accountImageString: image } =
          formatAccountData(account)

        accountImageString = image
        accountName = name
      }
  }

  if (!account) {
    return null
  }

  let profileString

  if (
    typeof account.handle === 'string' &&
    account.handle !== '[object Object]'
  ) {
    profileString = `/profiles/${account.handle}`
  } else {
    profileString = `/profiles/${account.publicKey}`
  }

  return (
    <>
      {disableLink && (
        <div className="flex flex-row items-center">
          <div className="mr-12 py-12">
            <ImageWithFallback
              className="imageBorderRound h-[40px] w-[40px] rounded-full "
              alt={accountName || 'image'}
              src={accountImageString || '/assets/empty-user.png'}
              height={40}
              width={40}
            />
          </div>
          <p className="body-1 line-clamp-1 overflow-hidden pl-12 text-black hover:text-opacity-80">
            {accountName}
          </p>
        </div>
      )}
      {!disableLink && (
        <div className="group flex w-full justify-between py-12">
          <Link href={profileString} className="flex flex-row items-center">
            <div className="mr-12">
              <ImageWithFallback
                className="imageBorderRound h-[40px] w-[40px]  max-w-[40px] rounded-full"
                alt={accountName || 'image'}
                src={accountImageString || '/assets/empty-user.png'}
                height={40}
                width={40}
              />
            </div>
            <p
              className={clsx(
                'body-1 line-clamp-1 overflow-hidden text-black hover:text-opacity-80',
                itemWidthOverride && 'w-full',
                !itemWidthOverride &&
                  inModal &&
                  'w-full md:w-[140px] md:break-words',
              )}
            >
              {accountName}
            </p>
          </Link>
          <div className="flex">
            <Follow
              publicKey={account.publicKey}
              displayName={account.displayName}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default memo(AccountListItem)
