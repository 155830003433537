'use client'

import clsx from 'clsx'
import Link from 'next/link'
import { useContext } from 'react'
import Audio from '@/contexts/AudioContext'
import {
  ButtonColor,
  ButtonVariant,
  QueueItem,
  TrackListType,
} from '@/lib/types'
import ImagePlaceholderBase64 from '@/lib/utils/ImagePlaceholderBase64'
import { formatDuration } from '@/lib/utils/formatDuration'
import ImageWithArweaveFallback from './ImageWithArweaveFallback'
import QueueListItemListBox from './QueueListItemListBox'
import Button from './tokens/Button'

type QueueListItemProps = {
  queueItem: QueueItem
  index: number
  parentLength?: number
  type: TrackListType
}

export default function QueueListItem(props: QueueListItemProps) {
  const { queueItem, index, parentLength, type } = props
  const { release, trackNumber } = queueItem
  const { handleDrawerToggle, playQueueItemAtIndex } = useContext(Audio.Context)
  const isAlbumTrack = release.metadata.properties.files.length > 1
  const { name } = release.metadata

  return (
    <div
      key={release.publicKey}
      className="queue-list-item flex justify-between border-b-1 border-blue-65 bg-blue-82 py-12"
    >
      <div className="flex ">
        <div className="group relative ml-0 mr-12 mt-0 h-40 w-40 min-w-[40px]">
          <ImageWithArweaveFallback
            src={release.metadata.image}
            alt={release.metadata.properties.title}
            height={40}
            width={40}
            className="imageBorder flex h-[40px] w-[40px] min-w-[40px] group-hover:hidden"
            placeholder="blur"
            blurDataURL={ImagePlaceholderBase64}
          />
          <span className="absolute left-1/2 top-1/2 mr-12 hidden w-[40px] min-w-[40px] -translate-x-1/2 -translate-y-1/2 transform group-hover:flex">
            <Button
              icon="playSmall"
              variant={ButtonVariant.Square}
              color={ButtonColor.Solid}
              onClick={() => playQueueItemAtIndex(release, index, false)}
            />
          </span>
        </div>

        <div className="pr-[12px]">
          <div className="flex">
            <Link
              href={`/releases/${release.slug || release.publicKey}`}
              onClick={handleDrawerToggle}
            >
              <p
                className={clsx(
                  'default-font !body-1',
                  isAlbumTrack ? 'text-blue-35' : 'text-white',
                )}
              >
                {!isAlbumTrack && name}
                {isAlbumTrack && (
                  <>
                    {release.metadata.properties.files.find(
                      (file) => file.track === trackNumber,
                    )?.track_title || trackNumber}
                    <span className="body-1 text-white">
                      {' / '}
                      {release.metadata.properties.title}
                    </span>
                  </>
                )}
              </p>
            </Link>
          </div>
          {release.hub && (
            <Link
              href={`/hubs/${release.hub.handle}`}
              onClick={handleDrawerToggle}
            >
              <p className="body-1 text-blue-35">
                {release.hub.data.displayName}
              </p>
            </Link>
          )}
          {!release.hub && (
            <Link
              href={`/profiles/${release.publisherAccount?.handle}`}
              onClick={handleDrawerToggle}
            >
              <p className="body-1 text-blue-35">
                {release.publisherAccount?.displayName}
              </p>
            </Link>
          )}
        </div>
      </div>

      <div className="flex items-start text-white ">
        <p className="ui-1-tabular mr-[12px] mt-[2px] lg:mr-[28px]">
          {formatDuration(
            release.metadata.properties.files.find(
              (file) => file.track === trackNumber,
            )?.duration || 0,
          )}
        </p>

        <QueueListItemListBox
          queueItem={queueItem}
          release={release}
          index={index}
          parentLength={parentLength}
          type={type}
        />
      </div>
    </div>
  )
}
