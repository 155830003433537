import Nina from '@nina-protocol/js-sdk'
import axios from 'axios'
import { PublicKeyString } from '../types'
import initSdk from './initSdk'
import { ninaErrorHandler } from './ninaErrorHandler'

type SubscriptionUnsubscribeDelegatedProps = {
  to: PublicKeyString
  sessionSignature: {
    message: string
    signature: string
    publicKey: string
  }
}
interface ErrorResponse {
  response?: {
    data: {
      error: string
    }
  }
  msg?: string
}

export const subscriptionUnsubscribeDelegated = async ({
  to,
  sessionSignature,
}: SubscriptionUnsubscribeDelegatedProps) => {
  const { message, publicKey, signature } = sessionSignature

  if (!sessionSignature) {
    return {
      success: false,
      msg: 'Failed to Unfollow: Session Signature is null',
    }
  }

  try {
    await initSdk()

    const { status, txid, subscription } = (
      await axios.post(`${process.env.NINA_ID_ENDPOINT}/unfollow`, {
        to,
        signature: signature,
        message: message,
        publicKey: publicKey,
      })
    ).data

    if (status === 'success') {
      try {
        await Nina.confirmTransaction(txid)
        await axios.get(
          `${process.env.NINA_API_ENDPOINT}/subscriptions/${subscription}?transactionId=${txid}`,
        )
      } catch (error) {
        console.warn(error)
      }

      return {
        success: true,
        msg: 'Successfully Unfollowed',
      }
    } else {
      return {
        success: false,
        msg: 'Failed to Unfollow',
      }
    }
  } catch (error) {
    console.warn(error)
    const errorResponse = error as ErrorResponse

    return ninaErrorHandler(errorResponse, 'Failed to Unfollow')
  }
}
