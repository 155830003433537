'use client'

import { usePathname } from 'next/navigation'
import { useContext, useEffect, useMemo, useState } from 'react'
import PopUpContext from '@/contexts/PopUpContext'
import Update from '@/contexts/UpdateContext'
import Wallet from '@/contexts/WalletContext'
import { fetchAccount } from '@/lib/account/fetchAccount'
import { Account } from '@/lib/types'
// import AccountVerificationsPopUp from './AccountVerificationsPopUp'
import ProfilePopUp from './ProfilePopUp'
import SuggestedFollowsPopUp from './SuggestedFollowsPopUp'

export default function PopUp() {
  const { wallet, profile, userSubscriptions } = useContext(Wallet.Context)
  const { date } = useContext(Update.Context)
  const { showPopUp, setShowPopUp } = useContext(PopUpContext.Context)
  const [account, setAccount] = useState<Account | null>(null)
  const [subscriptions, setSubscriptions] = useState(userSubscriptions)
  const path = usePathname()
  useEffect(() => {
    const handleFetchAccount = async () => {
      if (wallet.publicKey) {
        const account = await fetchAccount(wallet.publicKey.toBase58())
        setAccount(account)
      }
    }

    handleFetchAccount()
  }, [wallet.publicKey, date, userSubscriptions])

  useEffect(() => {
    if (wallet.publicKey) {
      setSubscriptions(userSubscriptions)
    }
  }, [date, userSubscriptions, wallet.publicKey])

  const [activePopUpIndex, setActivePopUpIndex] = useState(0)

  const popUpArray = useMemo(() => {
    if (!account || !subscriptions) return

    return [
      {
        displayCondition: account.image === '' || account.image === null,
        completed: account.image !== '',
        component: <ProfilePopUp profile={profile || account} />,
      },
      {
        displayCondition: subscriptions && subscriptions?.length === 0,
        completed: subscriptions && subscriptions?.length > 0,
        component: <SuggestedFollowsPopUp />,
      },
      // {
      //   displayCondition: account?.verifications?.length === 0,
      //   completed: account?.verifications && account?.verifications.length > 0,
      //   component: <AccountVerificationsPopUp />,
      // },
    ]
  }, [account])

  useEffect(() => {
    if (!account || !popUpArray || !subscriptions) return

    const currentPopUp = popUpArray[activePopUpIndex]

    if (
      account &&
      subscriptions &&
      popUpArray &&
      currentPopUp &&
      currentPopUp?.displayCondition === true
    ) {
      setShowPopUp(true)
    } else if (currentPopUp?.displayCondition === false) {
      setActivePopUpIndex(() => activePopUpIndex + 1)
    }

    if (activePopUpIndex === popUpArray.length) {
      setShowPopUp(false)
    }
  }, [activePopUpIndex, account, popUpArray, date])

  if (!popUpArray) return null

  const currentPopUp = popUpArray[activePopUpIndex]

  if (!currentPopUp) return null

  const { component } = currentPopUp
  const inAppSignUp = document.URL.includes('/app/signup')
  const inNinaNightFlow = path.includes('nina-night')

  if (inAppSignUp || inNinaNightFlow) return null

  return (
    <>
      {wallet.publicKey && showPopUp && (
        // TODO: variant for styling different pop ups
        <div className="fixed bottom-[64px] left-12 z-[9] h-auto w-[calc(100vw_-_24px)] border-1 border-grey-15 bg-white p-12 md:w-[376px] lg:bottom-[136px] lg:left-28">
          {component}
        </div>
      )}
    </>
  )
}
