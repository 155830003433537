import Nina from '@nina-protocol/js-sdk'
import { cache } from 'react'
import initSdk from '@/lib/utils/initSdk'
import { PaginationProps, PublicKeyString } from '../types'

export const fetchCollectorsForRelease = cache(
  async (
    releasePublicKey: PublicKeyString,
    pagination: PaginationProps = { limit: 20, offset: 0, sort: 'desc' },
    withCollection = false,
  ) => {
    await initSdk()

    const accountResponse = await Nina.Release.fetchCollectors(
      releasePublicKey,
      pagination,
      withCollection,
    )

    return accountResponse
  },
)
