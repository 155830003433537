import React from 'react'

export default function FollowListSkeleton() {
  const renderRows = () => {
    const rowsArray = []
    for (let i = 0; i < 9; i++) {
      rowsArray.push(
        <li
          key={i}
          className="body-1 shimmer grid-flow-cols flex h-[64px] w-full justify-between border-t-1 border-grey-10 bg-blue-5 py-12"
        />,
      )
    }

    return rowsArray
  }

  return <ul className="[&>*:first-child]:border-none">{renderRows()}</ul>
}
