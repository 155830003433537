'use client'

import PopUpContext from '@/contexts/PopUpContext'
import PopUp from './PopUp'

export default function PopUps({ children }: { children: React.ReactNode }) {
  return (
    <PopUpContext.Provider>
      <PopUp />
      {children}
    </PopUpContext.Provider>
  )
}
